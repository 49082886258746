import React, { useContext, useEffect, useRef, useState } from "react";
import { FaUserAstronaut } from "react-icons/fa";
import { RiDeleteBinFill } from "react-icons/ri";
import SimpleBar from "simplebar-react";
import upload from "../../assets/images/upload.png";
import { Controller, useForm } from "react-hook-form";
import Select, { components } from "react-select";
import "rc-slider/assets/index.css";
import Slider from "rc-slider";
import CustomStyles from "../../components/customStyles";
import { ThemeContext } from "../../provider/themeContext";
import { useLanguage } from "../../provider/languageContext";
import { AssistantContext } from "../../provider/assistantContext";
import axios from "axios";
import { API_URL, API_URL_CORE } from "../../utilities/url";
import storage from "../../services/storage";
import { Dropdown, Spinner } from "react-bootstrap";
import { ModalRemoveAssistant } from "../../components";
import TagInput from "./component/tagInput";
import { ToastContainer } from "react-toastify";
import { ToastrContext } from "../../provider/toastrContext";
import { SessionContext } from "../../provider/sessionContext";

const Assistant = () => {
  const {
    assistantList,
    setAssistantList,
    setAssistantSessionList,
    selectedAssistant,
    setSelectedAssistant,
    selectedSessionAssistant,
    setSelectedSessionAssistant,
    loadingAs,
    setLoadingAs,
    activeAssistant,
    setActiveAssistant,
    setDefaultAssistant,
  } = useContext(AssistantContext);
  const { sessionList, setSessionList, selectedSession, setSelectedSession } =
    useContext(SessionContext);
  const { theme } = useContext(ThemeContext);
  const { showSuccess, showError } = useContext(ToastrContext);
  const { messagesLang } = useLanguage();
  const simpleBarRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(upload);
  const [isMobileChatActive, setIsMobileChatActive] = useState(false);
  const [mode, setMode] = useState("show");
  const [loadItem, setLoadItem] = useState(true);
  const [searchItem, setSearchItem] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [state, setState] = useState({
    name: "",
    gender: "",
    language: "",
  });
  const [isDefault, setIsDefault] = useState(false);
  const handleIsDefault = (event) => {
    const isChecked = event.target.checked;
    setIsDefault(isChecked);
    setValue("is_default", isChecked);
  };
  const genders = [
    {
      value: "M",
      label: "Male",
    },
    {
      value: "F",
      label: "Female",
    },
  ];
  const ageGroups = [
    {
      value: "13 - 16",
      label: "Teenager (13 - 16)",
    },
    {
      value: "17 - 24",
      label: "Youth (17 - 24)",
    },
    {
      value: "25 - 30",
      label: "Young Adults (25 - 30)",
    },
    {
      value: "31 - 45",
      label: "Middle Aged Adults (31 - 45)",
    },
    {
      value: "45 - 59",
      label: "Old Adults (45 - 59)",
    },
    {
      value: "60 - Above",
      label: "Elderly (60 above)",
    },
    {
      value: "",
      label: "No Constraints",
    },
  ];
  const [creativity, setCreativity] = useState(0);
  const handleCreativity = (value) => {
    setCreativity(value.toFixed(2));
    setValue("creativity", value.toFixed(2));
  };
  const clarities = ["to_the_point", "descriptive", "narative"];
  const [clarity, setClarity] = useState(1);
  const clarity_mark = {
    1: "To The Point",
    2: "Descriptive",
    3: "Narative",
  };
  const handleClarity = (value) => {
    setClarity(value);
    setValue("clarity", clarities[value - 1]);
  };
  const emoticons = ["never", "rarely", "sometimes", "often", "always"];
  const [emoticon, setEmoticon] = useState(1);
  const emoticon_marks = {
    1: "Never",
    2: "Rarely",
    3: "Sometimes",
    4: "Often",
    5: "Always",
  };
  const handleEmoticon = (value) => {
    setEmoticon(value);
    setValue("use_emoji", emoticons[value - 1]);
  };
  const [areaTags, setAreaTags] = useState([]);
  const [salutationTags, setSalutationTags] = useState([]);
  const [conversationTags, setConversationTags] = useState([]);
  const [appearanceTags, setAppearanceTags] = useState([]);

  const handleClickAdd = () => {
    setActiveAssistant(null);
    setIsDefault(false);
    scrollToTop();
    setImagePreview(upload);
    setValue("idt_user_assistant", null);
    setValue("name", null);
    setValue("gender", null);
    setValue("age_group", null);
    setAreaTags([]);
    setSalutationTags([]);
    setConversationTags([]);
    setValue("area_of_expertise", null);
    setValue("salutation", null);
    setValue("conversation_style", null);
    setValue("appearance", null);
    setValue("custom_profile", null);
    // Emoticon
    setEmoticon(emoticons[0]);
    setValue("use_emoji", null);
    // Clarity
    setClarity(clarities[0]);
    setValue("clarity", null);
    // Creativity
    setCreativity(0);
    setValue("creativity", null);
    reset({
      idt_user_assistant: null,
      name: null,
      gender: null,
      age_group: null,
      custom_profile: null,
    });
    setMode("add");
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown")) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    callPersonalAssistant();
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    adjustTextareaHeight();
  }, [mode]);

  const callPersonalAssistant = () => {
    setLoadingAs(true);
    axios
      .post(
        API_URL + "get-personal-assistant-list",
        { page: 1, limit: 500 },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setAssistantList(
            response.data.data.data ? response.data.data.data : []
          );
          // setState((prevState) => ({
          //   ...prevState,
          //   name: response.data.data.data[0].name,
          //   gender: response.data.data.data[0].sex,
          //   age_group: response.data.data.data[0].age_group,
          // }));
          setValue("name", response.data.data.data[0].name);
          // Emoticon
          setEmoticon(
            emoticons.indexOf(response.data.data.data[0].use_emoji) + 1
          );
          setValue("use_emoji", response.data.data.data[0].use_emoji);
          // Clarity
          setClarity(clarities.indexOf(response.data.data.data[0].clarity) + 1);
          setValue("clarity", response.data.data.data[0].clarity);
          // Creativity
          setCreativity(response.data.data.data[0].creativity);
          setValue("creativity", response.data.data.data[0].creativity);
          // Gender
          setValue(
            "gender",
            genders.find((p) => p.value === response.data.data.data[0].sex)
          );
          setValue(
            "age_group",
            ageGroups.find(
              (p) => p.value === response.data.data.data[0].age_group
            )
          );
          setActiveAssistant(response.data.data.data[0].idt_user_assistant);
          setSelectedAssistant(response.data.data.data[0]);
          const areaExpertise = response.data.data.data[0].area_of_expertise;
          const DataArea = areaExpertise
            ? areaExpertise.split(",").map((item) => item.trim())
            : [];
          setAreaTags(DataArea);
          setValue("area_of_expertise", areaExpertise);
          const salutation = response.data.data.data[0].salutation;
          const DataSalutation = salutation
            ? salutation.split(",").map((item) => item.trim())
            : [];
          setSalutationTags(DataSalutation);
          setValue("salutation", salutation);
          const conversation = response.data.data.data[0].conversation_style;
          const DataConversation = conversation
            ? conversation.split(",").map((item) => item.trim())
            : [];
          setConversationTags(DataConversation);
          setValue("conversation_style", conversation);
          const appearance = response.data.data.data[0].appearance;
          const DataAppearance = appearance
            ? appearance.split(",").map((item) => item.trim())
            : [];
          setAppearanceTags(DataAppearance);
          setValue("appearance", appearance);
          setValue("custom_profile", response.data.data.data[0].custom_profile);
          adjustTextareaHeight();
          setFirstLoad(true);
        }
        setLoadingAs(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filteredItems =
    assistantList.length > 0
      ? assistantList.filter((item) =>
          item.name.toLowerCase().includes(searchItem.toLowerCase())
        )
      : [];

  const adjustTextareaHeight = () => {
    const elements = document.getElementsByName("custom_profile");
    if (elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        if (element) {
          element.style.height = "auto";
          element.style.height = `${element.scrollHeight}px`;
        }
      }
    }
  };

  const toggleMobileChatClass = async (item) => {
    if (activeAssistant !== item.idt_user_assistant) {
      item.idt_user === 0 ? setMode("show") : setMode("update");
      scrollToTop();
      clearErrors();
      setIsMobileChatActive(!isMobileChatActive);
      setActiveAssistant(item.idt_user_assistant);
      setIsDefault(item.default ? true : false);
      setValue("profile_pic", null);
      setImagePreview(item.profile_pic ? item.profile_pic : upload);
      setLoadItem(false);
      setSelectedAssistant(item);
      setValue("idt_user_assistant", item.idt_user_assistant);
      setValue("name", item.name);
      // Emoticon
      setEmoticon(emoticons.indexOf(item.use_emoji) + 1);
      setValue("use_emoji", item.use_emoji);
      // Clarity
      setClarity(clarities.indexOf(item.clarity) + 1);
      setValue("clarity", item.clarity);
      // Creativity
      setCreativity(item.creativity);
      setValue("creativity", item.creativity);
      // Gender
      setValue(
        "gender",
        genders.find((p) => p.value === item.sex)
      );
      setValue(
        "age_group",
        ageGroups.find((p) => p.value === item.age_group)
      );
      const areaExpertise = item.area_of_expertise;
      const DataArea = areaExpertise
        ? areaExpertise.split(",").map((item) => item.trim())
        : [];
      setAreaTags(DataArea);
      setValue("area_of_expertise", areaExpertise);
      const salutation = item.salutation;
      const DataSalutation = salutation
        ? salutation.split(",").map((item) => item.trim())
        : [];
      setSalutationTags(DataSalutation);
      setValue("salutation", salutation);
      const conversation = item.conversation_style;
      const DataConversation = conversation
        ? conversation.split(",").map((item) => item.trim())
        : [];
      setConversationTags(DataConversation);
      setValue("conversation_style", conversation);
      const appearance = item.appearance;
      const DataAppearance = appearance
        ? appearance.split(",").map((item) => item.trim())
        : [];
      setAppearanceTags(DataAppearance);
      setValue("appearance", appearance);
      setValue("custom_profile", item.custom_profile);
      adjustTextareaHeight();
    }
  };

  const handleSearch = (event) => {
    setSearchItem(event.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setValue("profile_pic", file);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    clearErrors(event.target.name);
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setError,
    clearErrors,
    watch,
    setValue,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(API_URL + "add-personal-assistant", data, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          if (response.data.data.default) {
            const updatedList = assistantList.map((assistant) => {
              if (assistant.default) {
                return { ...assistant, default: null };
              }
              return assistant;
            });
            setDefaultAssistant(response.data.data.idt_user_assistant);
            setAssistantList([...updatedList, response.data.data]);
          } else {
            setAssistantList([...assistantList, response.data.data]);
          }
          setAssistantSessionList((prev) => [...prev, response.data.data]);
          toggleMobileChatClass(response.data.data);
          setLoading(false);
          // loadData();
        } else {
          showError(response.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        showError("An error occurred. Please try again later.");
        setLoading(false);
      });
  };

  const onSubmitUpdate = (data) => {
    setLoading(true);
    axios
      .post(API_URL + "update-personal-assistant", data, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          if (sessionList.length > 0) {
            const updatedSessionList = sessionList.map((session) => {
              if (
                session.assistant.idt_user_assistant === data.idt_user_assistant
              ) {
                return {
                  ...session,
                  assistant: {
                    ...session.assistant,
                    profile_pic: response.data.data.profile_pic,
                  },
                };
              }
              return session;
            });
            setSessionList(updatedSessionList);
          }
          if (selectedSession) {
            setSelectedSession((prev) => {
              if (
                prev.assistant &&
                prev.assistant.idt_user_assistant === data.idt_user_assistant
              ) {
                return {
                  ...prev,
                  assistant: {
                    ...prev.assistant,
                    profile_pic: response.data.data.profile_pic,
                  },
                };
              }
              return prev;
            });
          }
          setAssistantList((prevList) =>
            prevList.map((data) =>
              data.idt_user_assistant === response.data.data.idt_user_assistant
                ? response.data.data
                : data
            )
          );
          setAssistantSessionList((prevList) =>
            prevList.map((data) =>
              data.idt_user_assistant === response.data.data.idt_user_assistant
                ? response.data.data
                : data
            )
          );
          selectedSessionAssistant.value ===
            response.data.data.idt_user_assistant &&
            setSelectedSessionAssistant({
              value: response.data.data.idt_user_assistant,
              label: response.data.data.name,
            });
          toggleMobileChatClass(response.data.data);
          setLoading(false);
        } else {
          showError(response.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onSetDefaultAssistant = (id) => {
    axios
      .post(
        API_URL + "set-default-assistant",
        { idt_user_assistant: id },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          const updatedList = assistantList.map((assistant) => {
            if (assistant.default) {
              return { ...assistant, default: null };
            }
            if (assistant.idt_user_assistant === id) {
              return {
                ...assistant,
                default: response.data.data,
              };
            }
            return assistant;
          });
          setDefaultAssistant(id);
          setAssistantList(updatedList);
          setLoading(false);
        }
        setOpenDropdown(null);
      })
      .catch((error) => {
        setOpenDropdown(null);
      });
  };

  const scrollToTop = () => {
    if (simpleBarRef.current) {
      simpleBarRef.current
        .getScrollElement()
        .scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const DropdownIndicator = (props) => {
    return props.selectProps.isDisabled ? null : (
      <components.DropdownIndicator {...props} />
    );
  };

  const handleArea = (data) => {
    setValue("area_of_expertise", data);
  };

  const handleSalutation = (data) => {
    setValue("salutation", data);
  };

  const handleConversation = (data) => {
    setValue("conversation_style", data);
  };

  const handleAppearance = (data) => {
    setValue("appearance", data);
  };

  const custom_profile = watch("custom_profile", "");
  const handleCustomeProfile = (event) => {
    setValue("custom_profile", event.target.value);
    adjustTextareaHeight();
  };

  const [openDropdown, setOpenDropdown] = useState(null); // Track which dropdown is open
  const handleDropdownToggle = (index) => {
    // Close the currently open dropdown if the same dropdown is clicked
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleGenImg = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(API_URL_CORE + "image/avatar", {
        gender: data.gender.label,
        age_group: data.age_group.value,
        expertise: data.area_of_expertise,
        conversation_style: data.conversation_style,
        specific_styling: data.appearance,
        style: "realistic",
      });

      if (response.data.errormsg === "OK") {
        const base64String = response.data.data.images[0];
        setImagePreview(`data:image/png;base64,${base64String}`);
        const base64Data = base64String.split(",")[0];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteNumbers], { type: "image/png" });
        const file = new File([blob], "profile_pic.png", { type: "image/png" });
        setValue("profile_pic", file);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error generating image:", error);
      setImagePreview(null);
      setLoading(false);
    }
  };

  return (
    <div className="page-content">
      <ToastContainer toastClassName="toaster" />
      <div className="container-fluid">
        <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
          <div className="chat-leftsidebar">
            <div className="p-3 d-flex flex-column h-100">
              <div className="search-box">
                <input
                  type="text"
                  className="form-control bg-light border-light"
                  placeholder="Search here..."
                  value={searchItem}
                  onChange={handleSearch}
                />
                <i className="ri-search-2-line search-icon"></i>
              </div>
              <div className="mt-2">
                <button
                  className="btn btn-md btn-primary w-100"
                  onClick={() => handleClickAdd()}
                >
                  Add Assistant
                </button>
              </div>
              <ul
                class="nav nav-tabs nav-tabs-custom nav-success nav-justified"
                role="tablist"
              >
                <li class="nav-item">
                  <a class="nav-link active fs-14" role="tab">
                    {""}
                  </a>
                </li>
              </ul>
              <SimpleBar className="chat-room-list pt-2">
                <div className="chat-message-list">
                  <ul
                    className="list-unstyled chat-list chat-user-list"
                    id="userList"
                  >
                    {loadingAs ? (
                      <div className="loading-session-list">
                        <Spinner animation="border" />
                      </div>
                    ) : filteredItems.length > 0 ? (
                      filteredItems.map((item, index) => (
                        <li
                          data-name="direct-message"
                          key={index}
                          className={
                            item.idt_user_assistant === activeAssistant ||
                            (loadItem === true && index === true)
                              ? "active"
                              : ""
                          }
                        >
                          <a className="chats rounded-2 p-2">
                            <div
                              className="d-flex align-items-center"
                              onClick={() => toggleMobileChatClass(item)}
                            >
                              <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                <div className="avatar-xxs">
                                  <FaUserAstronaut
                                    size={20}
                                    style={{ marginTop: "-2px" }}
                                  />
                                </div>
                              </div>
                              <div className="flex-grow-1 overflow-hidden">
                                <p
                                  className="texted text-truncate fs-14"
                                  style={{ marginBottom: "-3px" }}
                                >
                                  {item.name}
                                </p>
                                <p
                                  className="texted text-muted text-truncate fs-12"
                                  style={{ marginBottom: "-3px" }}
                                >
                                  {item.idt_user === 0
                                    ? "System Assistant"
                                    : "Personal Assistant"}{" "}
                                  {item.default && "(Default)"}
                                </p>
                              </div>
                              <div>
                                {item.idt_user !== 0 ? (
                                  <Dropdown
                                    show={openDropdown === index}
                                    className="card-btn-dropdown"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <Dropdown.Toggle
                                      className="btn delete c-icon mt-n1 p-0 dropdown"
                                      variant="none"
                                      onClick={() =>
                                        handleDropdownToggle(index)
                                      }
                                    >
                                      <i className="ri-more-2-fill fs-14 align-bottom"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu align="end">
                                      {!item.default && (
                                        <Dropdown.Item
                                          onClick={() =>
                                            onSetDefaultAssistant(
                                              item.idt_user_assistant
                                            )
                                          }
                                        >
                                          Set as Default
                                        </Dropdown.Item>
                                      )}
                                      <ModalRemoveAssistant
                                        item={item}
                                        onDropdown={setOpenDropdown}
                                        setId={
                                          selectedAssistant.idt_user_assistant
                                        }
                                        itemFirst={toggleMobileChatClass}
                                      />
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : item.idt_user === 0 && !item.default ? (
                                  <Dropdown
                                    show={openDropdown === index}
                                    className="card-btn-dropdown"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <Dropdown.Toggle
                                      className="btn delete c-icon mt-n1 p-0 dropdown"
                                      variant="none"
                                      onClick={() =>
                                        handleDropdownToggle(index)
                                      }
                                    >
                                      <i className="ri-more-2-fill fs-14 align-bottom"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu align="end">
                                      {!item.default && (
                                        <Dropdown.Item
                                          onClick={() =>
                                            onSetDefaultAssistant(
                                              item.idt_user_assistant
                                            )
                                          }
                                        >
                                          Set as Default
                                        </Dropdown.Item>
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : null}
                              </div>
                            </div>
                          </a>
                        </li>
                      ))
                    ) : null}
                  </ul>
                </div>
              </SimpleBar>
            </div>
          </div>
          {mode === "update" ? (
            <div
              className={
                isMobileChatActive
                  ? "user-chat personal-assistant-content w-100 p-3 py-0 user-chat-show"
                  : "user-chat personal-assistant-content w-100 p-3 py-0"
              }
            >
              {firstLoad && (
                <SimpleBar
                  ref={simpleBarRef}
                  className="mt-2 mx-n4 px-4"
                  style={{ height: "calc(100vh - 150px)" }}
                >
                  <div className="mx-n3 pt-4 px-4">
                    <div id="folder-list" className="mb-2">
                      <div className="row justify-content-beetwen g-2 mb-3">
                        <div className="col pb-3">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                              <h5 className="fs-16 mb-0">Personal Assistant</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <form onSubmit={handleSubmit(onSubmitUpdate)}>
                        <div className="row mt-4">
                          <div className="col-md-4 col-sm-6">
                            <div className="profile-user d-flex justify-content-center mx-auto mb-4">
                              <img
                                src={imagePreview ? imagePreview : upload}
                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                alt="user-profile-image"
                                style={{ height: "11rem", width: "11rem" }}
                              />
                              <div
                                className="avatar-xs p-0 rounded-circle profile-photo-edit"
                                style={{ right: "auto" }}
                              >
                                <input
                                  id="profile-img-file-input"
                                  type="file"
                                  name="photo_url"
                                  className="profile-img-file-input"
                                  accept="image/*"
                                  onChange={handleImageChange}
                                />
                                <label
                                  htmlFor="profile-img-file-input"
                                  className="profile-photo-edit-2 avatar-xs"
                                  style={{ left: "55px" }}
                                >
                                  <span className="avatar-title rounded-circle bg-light text-body">
                                    <i className="ri-camera-fill"></i>
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn btn-primary bg-gradient waves-effect waves-light"
                                style={{ width: "170px" }}
                                onClick={handleSubmit(handleGenImg)}
                                disabled={loading}
                              >
                                {loading ? (
                                  <>
                                    <Spinner
                                      className="flex-shrink-0"
                                      animation="border"
                                      size="sm"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <FaUserAstronaut
                                      className="me-2"
                                      size={14}
                                    />
                                    Generate AI Image
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6">
                            <div className="mb-4">
                              <label className="form-label w-100">
                                Assistant Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                {...register("name", {
                                  required: true,
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.name &&
                                errors.name.type === "required" && (
                                  <p className="errorMsg text-danger fs-7">
                                    Name is required.
                                  </p>
                                )}
                            </div>
                            <div className="mb-4">
                              <label className="form-label w-100">Gender</label>
                              <Controller
                                name="gender"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    styles={CustomStyles(theme)}
                                    className="custom-select"
                                    maxMenuHeight={200}
                                    options={genders}
                                    placeholder="Select Gender"
                                    onBlur={field.onBlur}
                                    onChange={(selectedGender) => {
                                      field.onChange(selectedGender);
                                      clearErrors("gender");
                                    }}
                                    isSearchable={true}
                                  />
                                )}
                              />
                            </div>
                            <div className="mb-4">
                              <label className="form-label w-100">
                                Language
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="language"
                                value="Auto Language"
                                disabled
                              />
                            </div>
                          </div>
                          <hr></hr>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Age Group</h5>
                            <p>
                              Atur kisaran umur asisten AI Anda ketika melakukan
                              interaksi
                            </p>
                          </div>
                          <div className="col-md-4 col-sm-6 mb-4">
                            <Controller
                              name="age_group"
                              control={control}
                              rules={{
                                required: "Age is required.",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  styles={CustomStyles(theme)}
                                  className="custom-select"
                                  maxMenuHeight={200}
                                  options={ageGroups}
                                  placeholder="Select Age Group"
                                  onBlur={field.onBlur}
                                  onChange={(selectedAge) => {
                                    field.onChange(selectedAge);
                                    clearErrors("age_group");
                                  }}
                                  isSearchable={true}
                                />
                              )}
                            />
                            {errors.age_group && (
                              <p className="errorMsg text-danger fs-7">
                                {errors.age_group.message}
                              </p>
                            )}
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Area of Expertise</h5>
                            <p>
                              Area of Expertise Atur keahlian asisten AI Anda
                              ketika melakukan interaksi
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleArea}
                              tags={areaTags}
                              setTags={setAreaTags}
                              onDelete={true}
                            />
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Salutation</h5>
                            <p>
                              Atur panggilan yang akan digunakan oleh asisten AI
                              Anda ketika melakukan interaksi
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleSalutation}
                              tags={salutationTags}
                              setTags={setSalutationTags}
                              onDelete={true}
                            />
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Conversation Style</h5>
                            <p>
                              Atur gaya bahasa yang akan digunakan oleh asisten
                              AI Anda Intimate Casual Consultative Formal
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleConversation}
                              tags={conversationTags}
                              setTags={setConversationTags}
                              onDelete={true}
                            />
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Appearance</h5>
                            <p>
                              Atur persona asisten AI Anda, termasuk fisik,
                              warna rambut, warna kulit dan lainnya
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleAppearance}
                              tags={appearanceTags}
                              setTags={setAppearanceTags}
                              onDelete={true}
                            />
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Emoji Usage</h5>
                            <p>
                              Atur frekuensi penggunaan emoji yang akan
                              digunakan asisten AI Anda saat berinteraksi Never,
                              Rarely, Sometimes, Often, dan Always
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <Slider
                              className="mt-2"
                              min={1}
                              max={5}
                              marks={emoticon_marks}
                              step={null}
                              onChange={handleEmoticon}
                              value={emoticon}
                              tipFormatter={(value) => emoticon_marks[value]}
                            />
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Clarity Level</h5>
                            <p>
                              Atur jawaban dari setiap pertanyaan yang diberikan
                              oleh asisten AI Anda selama melakukan interaksi To
                              the point, Descriptive, dan Narative
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <Slider
                              className="mt-2"
                              min={1}
                              max={3}
                              marks={clarity_mark}
                              step={null} // Makes the slider snap to the marks
                              onChange={handleClarity}
                              value={clarity}
                              tipFormatter={(value) => clarity_mark[value]} // Show tooltip with mark label
                            />
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Creativity Level</h5>
                            <p>
                              Atur tingkat kebebasan menjawab asisten AI Anda
                              selama melakukan interaksi Low dan High
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <div className="rc slider-container">
                              <Slider
                                min={0}
                                max={1}
                                step={0.01}
                                value={creativity}
                                onChange={handleCreativity}
                              />
                              <div className="rc slider-label">
                                <span>Low</span>
                                <span>{creativity}</span>
                                <span>High</span>
                              </div>
                            </div>
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Custom Profile</h5>
                            <p className="mb-0">
                              Atur kebebasan menjawab asisten AI Anda.
                            </p>
                          </div>
                          <div className="col-md-7 col-sm-6 mb-4">
                            <textarea
                              className="form-control mb-1"
                              name="custom_profile"
                              value={custom_profile}
                              maxLength={2000}
                              {...register("custom_profile", {
                                required: false,
                              })}
                              placeholder="Max. 2000 Character"
                              onChange={handleCustomeProfile}
                              style={{ minHeight: "150px", overflow: "hidden" }}
                            />
                            <div className="text-end">
                              {custom_profile ? custom_profile.length : 0}/2000
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-11 mb-2">
                            <button
                              type="submit"
                              class="btn btn-info waves-effect waves-light float-end w-sm"
                              disabled={loading}
                            >
                              {loading ? (
                                <>
                                  <Spinner
                                    className="flex-shrink-0"
                                    animation="border"
                                    size="sm"
                                  />
                                </>
                              ) : (
                                "Update"
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </SimpleBar>
              )}
            </div>
          ) : mode === "add" ? (
            <div
              className={
                isMobileChatActive
                  ? "user-chat personal-assistant-content w-100 p-3 py-0 user-chat-show"
                  : "user-chat personal-assistant-content w-100 p-3 py-0"
              }
            >
              {firstLoad && (
                <SimpleBar
                  ref={simpleBarRef}
                  className="mt-2 mx-n4 px-4"
                  style={{ height: "calc(100vh - 150px)" }}
                >
                  <div className="mx-n3 pt-4 px-4">
                    <div id="folder-list" className="mb-2">
                      <div className="row justify-content-beetwen g-2 mb-3">
                        <div className="col pb-3">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                              <h5 className="fs-16 mb-0">
                                Add Personal Assistant
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mt-4">
                          <div className="col-md-4 col-sm-6">
                            <div className="profile-user d-flex justify-content-center mx-auto mb-4">
                              <img
                                src={imagePreview ? imagePreview : upload}
                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                alt="user-profile-image"
                                style={{ height: "11rem", width: "11rem" }}
                              />
                              <div
                                className="avatar-xs p-0 rounded-circle profile-photo-edit"
                                style={{ right: "auto" }}
                              >
                                <input
                                  id="profile-img-file-input"
                                  type="file"
                                  name="photo_url"
                                  className="profile-img-file-input"
                                  accept="image/*"
                                  onChange={handleImageChange}
                                />
                                <label
                                  htmlFor="profile-img-file-input"
                                  className="profile-photo-edit-2 avatar-xs"
                                  style={{ left: "55px" }}
                                >
                                  <span className="avatar-title rounded-circle bg-light text-body">
                                    <i className="ri-camera-fill"></i>
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                style={{ width: "120px" }}
                                onClick={handleSubmit(handleGenImg)}
                                disabled={loading}
                              >
                                {loading ? (
                                  <>
                                    <Spinner
                                      className="flex-shrink-0"
                                      animation="border"
                                      size="sm"
                                    />
                                  </>
                                ) : (
                                  "Generate AI Image"
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6">
                            <div className="mb-4">
                              <label className="form-label w-100">
                                Assistant Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                {...register("name", {
                                  required: true,
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.name &&
                                errors.name.type === "required" && (
                                  <p className="errorMsg text-danger fs-7">
                                    Name is required.
                                  </p>
                                )}
                            </div>
                            <div className="mb-4">
                              <label className="form-label w-100">Gender</label>
                              <Controller
                                name="gender"
                                control={control}
                                rules={{
                                  required: "Gender is required.",
                                }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    styles={CustomStyles(theme)}
                                    className="custom-select"
                                    maxMenuHeight={200}
                                    options={genders}
                                    placeholder="Select Gender"
                                    onBlur={field.onBlur}
                                    onChange={(selectedGender) => {
                                      field.onChange(selectedGender);
                                      clearErrors("gender");
                                    }}
                                    isSearchable={true}
                                  />
                                )}
                              />
                              {errors.gender && (
                                <p className="errorMsg text-danger fs-7">
                                  {errors.gender.message}
                                </p>
                              )}
                            </div>
                            <div className="mb-4">
                              <label className="form-label w-100">
                                Language
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="language"
                                value="Auto Language"
                                disabled
                              />
                            </div>
                          </div>
                          <hr></hr>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Age Group</h5>
                            <p>
                              Atur kisaran umur asisten AI Anda ketika melakukan
                              interaksi
                            </p>
                          </div>
                          <div className="col-md-4 col-sm-6 mb-4">
                            <Controller
                              name="age_group"
                              control={control}
                              rules={{
                                required: "Age is required.",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  styles={CustomStyles(theme)}
                                  className="custom-select"
                                  maxMenuHeight={200}
                                  options={ageGroups}
                                  placeholder="Select Age Group"
                                  onBlur={field.onBlur}
                                  onChange={(selectedAge) => {
                                    field.onChange(selectedAge);
                                    clearErrors("age_group");
                                  }}
                                  isSearchable={true}
                                />
                              )}
                            />
                            {errors.age_group && (
                              <p className="errorMsg text-danger fs-7">
                                {errors.age_group.message}
                              </p>
                            )}
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Area of Expertise</h5>
                            <p>
                              Area of Expertise Atur keahlian asisten AI Anda
                              ketika melakukan interaksi
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleArea}
                              tags={areaTags}
                              setTags={setAreaTags}
                              onDelete={true}
                            />
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Salutation</h5>
                            <p>
                              Atur panggilan yang akan digunakan oleh asisten AI
                              Anda ketika melakukan interaksi
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleSalutation}
                              tags={salutationTags}
                              setTags={setSalutationTags}
                              onDelete={true}
                            />
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Conversation Style</h5>
                            <p>
                              Atur gaya bahasa yang akan digunakan oleh asisten
                              AI Anda Intimate Casual Consultative Formal
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleConversation}
                              tags={conversationTags}
                              setTags={setConversationTags}
                              onDelete={true}
                            />
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Appearance</h5>
                            <p>
                              Atur persona asisten AI Anda, termasuk fisik,
                              warna rambut, warna kulit dan lainnya
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleAppearance}
                              tags={appearanceTags}
                              setTags={setAppearanceTags}
                              onDelete={true}
                            />
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Emoji Usage</h5>
                            <p>
                              Atur frekuensi penggunaan emoji yang akan
                              digunakan asisten AI Anda saat berinteraksi Never,
                              Rarely, Sometimes, Often, dan Always
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <Slider
                              className="mt-2"
                              min={1}
                              max={5}
                              marks={emoticon_marks}
                              step={null}
                              onChange={handleEmoticon}
                              value={emoticon}
                              tipFormatter={(value) => emoticon_marks[value]}
                            />
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Clarity Level</h5>
                            <p>
                              Atur jawaban dari setiap pertanyaan yang diberikan
                              oleh asisten AI Anda selama melakukan interaksi To
                              the point, Descriptive, dan Narative
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <Slider
                              className="mt-2"
                              min={1}
                              max={3}
                              marks={clarity_mark}
                              step={null} // Makes the slider snap to the marks
                              onChange={handleClarity}
                              value={clarity}
                              tipFormatter={(value) => clarity_mark[value]} // Show tooltip with mark label
                            />
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Creativity Level</h5>
                            <p>
                              Atur tingkat kebebasan menjawab asisten AI Anda
                              selama melakukan interaksi Low dan High
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <div className="rc slider-container">
                              <Slider
                                min={0}
                                max={1}
                                step={0.01}
                                value={creativity}
                                onChange={handleCreativity}
                              />
                              <div className="rc slider-label">
                                <span>Low</span>
                                <span>{creativity}</span>
                                <span>High</span>
                              </div>
                            </div>
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Custom Profile</h5>
                            <p className="mb-0">
                              Atur kebebasan menjawab asisten AI Anda.
                            </p>
                          </div>
                          <div className="col-md-7 col-sm-6 mb-4">
                            <textarea
                              className="form-control mb-1"
                              name="custom_profile"
                              value={custom_profile}
                              maxLength={2000}
                              {...register("custom_profile", {
                                required: false,
                              })}
                              onChange={handleCustomeProfile}
                              placeholder="Max 2000 Character"
                              style={{ minHeight: "150px", overflow: "hidden" }}
                            />
                            <div className="text-end">
                              {custom_profile ? custom_profile.length : 0}/2000
                            </div>
                          </div>
                          <div class="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <div
                              class="form-check form-switch form-switch-right form-switch-md"
                              style={{ width: "150px" }}
                            >
                              <label htmlFor="as-default" class="fw-bold">
                                Set As Default
                              </label>
                              <input
                                class="form-check-input code-switcher"
                                type="checkbox"
                                id="as-default"
                                checked={isDefault}
                                onChange={handleIsDefault}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-11 mb-2">
                            <button
                              type="submit"
                              class="btn btn-primary waves-effect waves-light float-end w-sm"
                              disabled={loading}
                            >
                              {loading ? (
                                <>
                                  <Spinner
                                    className="flex-shrink-0"
                                    animation="border"
                                    size="sm"
                                  />
                                </>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </SimpleBar>
              )}
            </div>
          ) : (
            <div
              className={
                isMobileChatActive
                  ? "user-chat personal-assistant-content w-100 p-3 py-0 user-chat-show"
                  : "user-chat personal-assistant-content w-100 p-3 py-0"
              }
            >
              {firstLoad && (
                <SimpleBar
                  ref={simpleBarRef}
                  className="mt-2 mx-n4 px-4"
                  style={{ height: "calc(100vh - 150px)" }}
                >
                  <div className="mx-n3 pt-4 px-4">
                    <div id="folder-list" className="mb-2">
                      <div className="row justify-content-beetwen g-2 mb-3">
                        <div className="col pb-3">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                              <h5 className="fs-16 mb-0">Personal Assistant</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-4 col-sm-6">
                          <div className="profile-user d-flex justify-content-center mx-auto mb-4">
                            <img
                              src={imagePreview ? imagePreview : upload}
                              className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                              alt="user-profile-image"
                              style={{ height: "11rem", width: "11rem" }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="mb-4">
                            <label className="form-label w-100">
                              Assistant Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              {...register("name", {
                                required: true,
                              })}
                              disabled
                            />
                          </div>
                          <div className="mb-4">
                            <label className="form-label w-100">Gender</label>
                            <Controller
                              name="gender"
                              control={control}
                              rules={{
                                required: "Gender is required.",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  styles={CustomStyles(theme)}
                                  className="custom-select"
                                  maxMenuHeight={200}
                                  options={genders}
                                  components={{ DropdownIndicator }}
                                  isDisabled={true}
                                  isSearchable={true}
                                />
                              )}
                            />
                            {errors.gender && (
                              <p className="errorMsg text-danger fs-7">
                                {errors.gender.message}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="form-label w-100">Language</label>
                            <input
                              type="text"
                              className="form-control"
                              name="language"
                              value="Auto Language"
                              disabled
                            />
                          </div>
                        </div>
                        <hr></hr>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Age Group</h5>
                          <p>
                            Atur kisaran umur asisten AI Anda ketika melakukan
                            interaksi
                          </p>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-4">
                          <Controller
                            name="age_group"
                            control={control}
                            rules={{
                              required: "Age is required.",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                styles={CustomStyles(theme)}
                                className="custom-select"
                                maxMenuHeight={200}
                                options={ageGroups}
                                components={{ DropdownIndicator }}
                                isDisabled={true}
                                isSearchable={true}
                              />
                            )}
                          />
                          {errors.age_group && (
                            <p className="errorMsg text-danger fs-7">
                              {errors.age_group.message}
                            </p>
                          )}
                        </div>
                        <div class="w-100 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Area of Expertise</h5>
                          <p>
                            Area of Expertise Atur keahlian asisten AI Anda
                            ketika melakukan interaksi
                          </p>
                        </div>
                        <div className="col-md-5 col-sm-6 mb-4">
                          <TagInput
                            onTagsChange={handleArea}
                            tags={areaTags}
                            setTags={setAreaTags}
                            onDelete={false}
                          />
                        </div>
                        <div class="w-100 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Salutation</h5>
                          <p>
                            Atur panggilan yang akan digunakan oleh asisten AI
                            Anda ketika melakukan interaksi
                          </p>
                        </div>
                        <div className="col-md-5 col-sm-6 mb-4">
                          <TagInput
                            onTagsChange={handleSalutation}
                            tags={salutationTags}
                            setTags={setSalutationTags}
                            onDelete={false}
                          />
                        </div>
                        <div class="w-100 d-none d-md-block"></div>
                        <div class="w-100 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Conversation Style</h5>
                          <p>
                            Atur gaya bahasa yang akan digunakan oleh asisten AI
                            Anda Intimate Casual Consultative Formal
                          </p>
                        </div>
                        <div className="col-md-5 col-sm-6 mb-4">
                          <TagInput
                            onTagsChange={handleConversation}
                            tags={conversationTags}
                            setTags={setConversationTags}
                            onDelete={false}
                          />
                        </div>
                        <div class="w-100 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Appearance</h5>
                          <p>
                            Atur persona asisten AI Anda, termasuk fisik, warna
                            rambut, warna kulit dan lainnya
                          </p>
                        </div>
                        <div className="col-md-5 col-sm-6 mb-4">
                          <TagInput
                            onTagsChange={handleAppearance}
                            tags={appearanceTags}
                            setTags={setAppearanceTags}
                            onDelete={false}
                          />
                        </div>
                        <div class="w-100 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Emoji Usage</h5>
                          <p>
                            Atur frekuensi penggunaan emoji yang akan digunakan
                            asisten AI Anda saat berinteraksi Never, Rarely,
                            Sometimes, Often, dan Always
                          </p>
                        </div>
                        <div className="col-md-5 col-sm-6 mb-4">
                          <Slider
                            className="mt-2"
                            min={1}
                            max={5}
                            marks={emoticon_marks}
                            step={null}
                            disabled={true}
                            value={emoticon}
                            tipFormatter={(value) => emoticon_marks[value]}
                          />
                        </div>
                        <div class="w-100 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Clarity Level</h5>
                          <p>
                            Atur jawaban dari setiap pertanyaan yang diberikan
                            oleh asisten AI Anda selama melakukan interaksi To
                            the point, Descriptive, dan Narative
                          </p>
                        </div>
                        <div className="col-md-5 col-sm-6 mb-4">
                          <Slider
                            className="mt-2"
                            min={1}
                            max={3}
                            marks={clarity_mark}
                            step={null} // Makes the slider snap to the marks
                            disabled={true}
                            value={clarity}
                            tipFormatter={(value) => clarity_mark[value]} // Show tooltip with mark label
                          />
                        </div>
                        <div class="w-100 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Creativity Level</h5>
                          <p>
                            Atur tingkat kebebasan menjawab asisten AI Anda
                            selama melakukan interaksi Low dan High
                          </p>
                        </div>
                        <div className="col-md-5 col-sm-6 mb-4">
                          <div className="rc slider-container">
                            <Slider
                              min={0}
                              max={1}
                              step={0.01}
                              value={creativity}
                              disabled={true}
                            />
                            <div className="rc slider-label">
                              <span>Low</span>
                              <span>{creativity}</span>
                              <span>High</span>
                            </div>
                          </div>
                        </div>
                        <div class="w-100 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Custom Profile</h5>
                          <p>Atur kebebasan menjawab asisten AI Anda.</p>
                        </div>
                        <div className="col-md-7 col-sm-6 mb-4">
                          <textarea
                            className="form-control"
                            name="custom_profile"
                            {...register("custom_profile", {
                              required: false,
                            })}
                            disabled
                            style={{ minHeight: "150px", overflow: "hidden" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </SimpleBar>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Assistant;
